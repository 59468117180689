import CrmProjects from '../../crmProject';
import CrmTower from '../../crmTower';
import PrjFloor from '../../../project/projectFloor';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'AddCrmFloor',
  components: {
    CrmProjects,
    CrmTower,
    PrjFloor,
    DatePicker
  },
  props: ['crmFloorRowData'],
  data() {
    return {
      unsubscribe: null,
      showCrmFloorModal: false,
      showValuesetForm: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      defaultValue: {
        value: null,
        text: null
      },
      crmPrj: {
        value: null,
        text: null
      },
      prjProject: {
        value: null,
        text: null
      },
      crmTower: {
        value: null,
        text: null
      },
      prjTower: {
        value: null,
        text: null
      },
      prjFloor: {
        value: null,
        text: null
      },
      floorType: {
        value: null,
        text: null
      },
      startDate: null,
      endDate: null,
      start_date_end_date: null,
      crmFloorCode: null,
      crmFloorName: null,
      crmFloorId: 0,
      tab: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmFloor();
          this.showAlert = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.crmFloorId });
        }
      }
    });
    if (this.crmFloorRowData !== null) {
      this.crmFloorCode = this.crmFloorRowData.crm_floor_code;
      this.crmFloorId = this.crmFloorRowData.crm_floor_id;
      this.crmFloorName = this.crmFloorRowData.crm_floor_name;
      this.floorType = {
        value: this.crmFloorRowData.crm_floor_type_vset,
        text: this.crmFloorRowData.crm_floor_type
      };
      this.crmTower = {
        value: this.crmFloorRowData.crm_tower_id,
        text: this.crmFloorRowData.crm_tower_name
      };
      this.prjTower = {
        value: this.crmFloorRowData.project_tower_id,
        text: this.crmFloorRowData.project_tower_name
      };
      this.crmPrj = {
        value: this.crmFloorRowData.crm_project_id,
        text: this.crmFloorRowData.crm_project_name
      };
      this.prjFloor = {
        value: this.crmFloorRowData.project_floor_id,
        text: this.crmFloorRowData.project_floor_name
      };
      this.start_date_end_date = [
        new Date(this.crmFloorRowData.start_date),
        new Date(this.crmFloorRowData.end_date)
      ];
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmProjects') {
        return (this.childName = 'CRM Projects');
      } else if (tab === 'CrmTower') {
        return (this.childName = 'CRM Tower');
      } else if (tab === 'PrjFloor') {
        return (this.childName = 'PRJ Floor');
      } else if (tab === 'PrjTower') {
        return (this.childName = 'PRJ Tower');
      }
    },
    addEditCrmFloor() {
      const payload = {
        crm_floor_code: this.crmFloorCode,
        crm_floor_id: this.crmFloorId,
        crm_floor_name: this.crmFloorName,
        crm_floor_type_vset: this.floorType.value,
        crm_tower_id: this.crmTower.value,
        project_floor_id: this.prjFloor.value,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1])
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmFloor', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmFloorList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    hideModalComponent(flag, component) {
      if (component === 'CrmTower' && !this.crmPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
      }
      this.showCrmFloorModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedCrmProj(item) {
      this.showCrmFloorModal = false;
      this.crmPrj = {
        value: item.crm_project_id,
        text: item.crm_project_name
      };
      this.prjProject = {
        value: item.project_project_id,
        text: item.project_project_name
      };
    },
    selectedCrmTower(item) {
      this.showCrmFloorModal = false;
      this.crmTower = {
        value: item.crm_tower_id,
        text: item.crm_tower_name
      };
      this.prjTower = {
        value: item.project_tower_id,
        text: item.project_tower_name
      };
    },
    projectFloorDetails(item) {
      this.showCrmFloorModal = false;
      this.prjFloor = {
        value: item.project_floor_id,
        text: item.floor_name
      };
    },
    openValueSet() {
      this.showValuesetForm = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: 'FLOOR_TYPE_VSET',
          multiFlag: false
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(vsetName) {
      this.floorType = {
        text: vsetName.value_meaning,
        value: vsetName.value_code
      };
      this.showValuesetForm = false;
    },
    closeValueSetModal() {
      this.showValuesetForm = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.floorType.value) {
        this.floorType = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
