import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import CrmProjects from '../crmProject';
import CrmTower from '../crmTower';
import PrjTower from '../../project/projectTower';
import AddCrmFloor from '../crmFloor/addCrmFloor';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'crmTower',
  components: {
    CrmProjects,
    CrmTower,
    PrjTower,
    AddCrmFloor
  },
  watch: {
    currentPage: function() {},
    perPage: function() {
      this.currentPage = 1;
    }
  },
  data() {
    return {
      unsubscribe: null,
      showcrmFloorModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      currentPage: 1,
      payload: {},
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      crmPrj: {
        value: null,
        text: null
      },
      crmTower: {
        value: null,
        text: null
      },
      prjTower: {
        value: null,
        text: null
      },
      crmFloorCode: '',
      crmFloorName: '',
      tab: null,
      crmFloorRowData: null,
      crmFloorData: [],
      crmFloorFields: [
        {
          key: 'crm_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_name',
          class: 'd-none'
        },
        {
          key: 'crm_floor_code',
          label: 'CRM Floor Code'
        },
        {
          key: 'crm_floor_id',
          class: 'd-none'
        },
        {
          key: 'crm_floor_name',
          label: 'CRM Floor Name'
        },
        {
          key: 'crm_floor_type',
          label: 'CRM Floor Type'
        },
        {
          key: 'master_project_name',
          class: 'd-none'
        },
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'project_floor_name',
          label: 'PRJ Floor Name'
        },
        {
          key: 'project_floor_id',
          class: 'd-none'
        },
        {
          key: 'crm_tower_id',
          class: 'd-none'
        },
        {
          key: 'crm_tower_name',
          class: 'd-none'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'sequence',
          class: 'd-none'
        }
      ]
    };
  },
  validations: {
    crmPrj: {
      text: {
        required
      }
    },
    crmTower: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideCrmFloorModal(true, 'AddCrmFloor');
          this.crmFloorRowData = null;
        }
        if (actionName === 'download' && !this.showcrmFloorModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmFloorList',
            'crm-floor',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmProjects') {
        return (this.childName = 'CRM Projects');
      } else if (tab === 'CrmTower') {
        return (this.childName = 'CRM Tower');
      } else if (tab === 'PrjTower') {
        return (this.childName = 'PRJ Tower');
      } else if (tab === 'AddCrmFloor') {
        return (this.childName = 'Add CRM Floor');
      }
    },
    hideCrmFloorModal(flag, component) {
      if (component === 'CrmTower' && !this.crmPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
      }
      this.showcrmFloorModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    getCrmFloorList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          crm_project_id: this.crmPrj.value,
          crm_tower_id: this.crmTower.value,
          project_tower_id: this.prjTower.value,
          crm_floor_code: this.crmFloorCode,
          crm_floor_name: this.crmFloorName
        };
        this.loader = true;
        this.$store
          .dispatch('crmInventoryProperty/getCrmFloorList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.crmFloorData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    rowSelected(rowData) {
      this.crmFloorRowData = rowData;
      this.hideCrmFloorModal(true, 'AddCrmFloor');
    },
    selectedCrmProj(item) {
      this.showcrmFloorModal = false;
      this.crmPrj = {
        value: item.crm_project_id,
        text: item.crm_project_name
      };
    },
    selectedCrmTower(item) {
      this.showcrmFloorModal = false;
      this.crmTower = {
        value: item.crm_tower_id,
        text: item.crm_tower_name
      };
      this.prjTower = {
        value: item.project_tower_id,
        text: item.project_tower_name
      };
    },
    updateCrmFloorList() {
      this.getCrmFloorList();
    },
    resetSearchFilters() {
      this.crmPrj = {
        value: null,
        text: null
      };
      this.crmTower = {
        value: null,
        text: null
      };
      this.prjTower = {
        value: null,
        text: null
      };
      this.crmFloorCode = '';
      this.crmFloorName = '';
      this.crmFloorData = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
